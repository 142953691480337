import styled from 'styled-components';
export const LoaderSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-color: white transparent;
  border-radius: 50%;
  display: inline-block;
  animation: lds-dual-ring 1.2s linear infinite;

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;