import { Link, StepText, StepTitle, Title } from '../StyledComponents/WrapperTypography';
import { Step, Table } from '../StyledComponents/WrapperStyledComponents';
import React from 'react';
import { linkTrustwallet } from '../../../utils/constants';

const SeedScreenContent = () => (
  <>
    <Title>
      <span style={{ color: 'black' }}>Simple use of your</span> seed phrase
    </Title>
    <Table>
      <Step>
        <StepTitle>Step 1. Download Trust wallet</StepTitle>
        <StepText>
          To use the wallet, you need to download it from the{' '}
          <Link target="_blank" href={linkTrustwallet}>
            official site.
          </Link>
        </StepText>
      </Step>
      <Step>
        <StepTitle>Step 2. Import your seed phrase</StepTitle>
        <StepText>
          Paste or type the secret phrase in the special field.
        </StepText>
      </Step>
      <Step>
        <StepTitle>Step 3. Enjoy using your wallet</StepTitle>
        <StepText>
          Now you can fully manage your assets in your wallet.
        </StepText>
      </Step>
    </Table>
  </>
);

export default SeedScreenContent;
