import ReactDOM from 'react-dom/client';
import React from 'react';
import { App } from './App';

export const renderUI = (rootElement?: HTMLElement) => {
  if (!rootElement) {
    throw Error('root element is not defined');
  }

  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};
