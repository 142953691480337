import React from 'react';

export const Shield = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.54 3.12L10.04 1.06C9.46999 0.849996 8.53999 0.849996 7.96999 1.06L2.46999 3.12C1.40999 3.52 0.549988 4.76 0.549988 5.89V13.99C0.549988 14.8 1.07999 15.87 1.72999 16.35L7.22999 20.46C8.19999 21.19 9.78999 21.19 10.76 20.46L16.26 16.35C16.91 15.86 17.44 14.8 17.44 13.99V5.89C17.45 4.76 16.59 3.52 15.54 3.12ZM12.48 8.72L8.17999 13.02C8.02999 13.17 7.83999 13.24 7.64999 13.24C7.45999 13.24 7.26999 13.17 7.11999 13.02L5.51999 11.4C5.22999 11.11 5.22999 10.63 5.51999 10.34C5.80999 10.05 6.28999 10.05 6.57999 10.34L7.65999 11.42L11.43 7.65C11.72 7.36 12.2 7.36 12.49 7.65C12.78 7.94 12.78 8.43 12.48 8.72Z"
      fill="#292D32"
    />
  </svg>
);
