type Entity = 'token' | 'sessionId';

const TOKEN_KEY = 'token';
const SESSION_ID_KEY = 'sessionId';

const storage = sessionStorage;

interface Methods {
  get: () => string | null;
  set: (value: string) => void;
  remove: () => void;
}

const keys: Array<Entity> = [TOKEN_KEY, SESSION_ID_KEY];
const storageApiDefault = {} as Record<Entity, Methods>;

for (const key of keys) {
  storageApiDefault[key] = {
    get: () => storage.getItem(key),
    set: (value: string) => storage.setItem(key, value),
    remove: () => storage.removeItem(key),
  };
}

const storageApi = storageApiDefault as Record<Entity, Methods>;

const clearStorage = () => storage.clear()

export { storageApi, clearStorage };
