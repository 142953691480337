import styled from 'styled-components';
import { mobileBreakpoint, tabletBreakpoint } from '../../../utils/constants';

const garyTextColor = 'rgba(111, 114, 119, 1)';

export const Title = styled.h1`
  width: 550px;
  margin: 42px 0 0 0;
  color: rgba(244, 128, 62, 1);
  font-family: Satoshi, Arial, sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;

  @media screen and (max-width: ${tabletBreakpoint}) {
    margin: 0;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    margin-top: 80px;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Paragraph = styled.p`
  width: 350px;
  margin: 16px 0 0 0;
  color: ${garyTextColor};
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: ${tabletBreakpoint}) {
    width: 100%;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

export const Note = styled.p`
  width: 390px;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  color: ${garyTextColor};

  @media screen and (max-width: ${tabletBreakpoint}) {
    width: 100%;
    margin: 0;
  }
`;

export const AdvantagesText = styled.p`
  width: 90%;
  margin: 0;
  font-size: 18px;
  line-height: 24px;

  @media screen and (max-width: ${mobileBreakpoint}) {
    font-size: 16px;
  }
`;

export const StepTitle = styled.h2`
  margin: 0;
  font-family: Satoshi, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

export const StepText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: ${garyTextColor};
`;

export const Copyright = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 20px;
`;

export const Link = styled.a`
  color: rgba(244, 102, 62, 1);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const FooterLink = styled.a`
  color: rgba(244, 128, 62, 1);
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
