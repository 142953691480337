import React, { useCallback, useState } from 'react';
import SeedPhraseInput from '../../../Components/SeedPhraseInput';
import DeleteButton from '../../../Components/WidgetWrapper/DeleteButton';
import { WidgetWrapper } from '../../../Components/WidgetWrapper';
import { TableSeedPhrases } from './SeedPhrasesStyleComponents';

type ShowSeedPhrasesProps = {
  seedPhrases: Array<String> | undefined;
  onCLickDeleteButton: () => void;
  seedPhrasesLoading: boolean;
};
export const ShowSeedPhrases = ({
  seedPhrases,
  onCLickDeleteButton,
  seedPhrasesLoading,
}: ShowSeedPhrasesProps) => {
  const [copied, setCopied] = useState(false);

  const seedPhraseToCopy = seedPhrases?.join(' ');

  const copyCompleted = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleCopySeedPhrases = useCallback(() => {
    navigator.clipboard
      .writeText(seedPhraseToCopy ? seedPhraseToCopy : '')
      .then()
      .catch((err) => console.error(err));
    copyCompleted();
  }, [seedPhraseToCopy]);

  return (
    <WidgetWrapper
      onClickFooterButton={handleCopySeedPhrases}
      footerButtonLabel={copied ? 'Copied to clipboard' : 'Copy seed phrase'}
      title="Secret seed phrase"
      text="This phrase is the ONLY way to login your wallet. Save the phrase somewhere safe."
    >
      <TableSeedPhrases>
        {seedPhrases &&
          seedPhrases.map((phrase, index) => {
            return (
              <SeedPhraseInput
                key={index + 1}
                number={index + 1}
                text={phrase.toString()}
                isLoading={seedPhrasesLoading}
                readOnly
              />
            );
          })}
        <DeleteButton
          label="Delete from our server"
          onClick={onCLickDeleteButton}
        />
      </TableSeedPhrases>
    </WidgetWrapper>
  );
};
