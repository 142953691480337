import React from 'react';
import { WidgetWrapper } from '../../../Components/WidgetWrapper';
import { linkBuyCrypto } from '../../../../utils/constants';
import { CompleteIcon } from '../../../Components/svg/CompleteIcon';
import { StateWrapper } from './SeedPhrasesStyleComponents';
import { StateText, StateTitle } from '../../../Components/Common/Typography';

export const ShowComplete = () => {
  const redirectToBuy = () => {
    window.open(linkBuyCrypto, '_self');
  };

  return (
    <WidgetWrapper
      onClickFooterButton={redirectToBuy}
      footerButtonLabel="Buy crypto"
    >
      <StateWrapper>
        <CompleteIcon />
        <StateTitle>Secret seed phrase deleted successfully!</StateTitle>
        <StateText>
          We hope that you saved your seed phrase, as it will not be possible to
          restore it.
        </StateText>
      </StateWrapper>
    </WidgetWrapper>
  );
};
