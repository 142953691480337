import { AdvantagesText, Paragraph, Title } from '../StyledComponents/WrapperTypography';
import { Advantages, Table } from '../StyledComponents/WrapperStyledComponents';
import { AdvantagesWallet } from '../../Components/svg/AdvantagesWallet';
import { Shield } from '../../Components/svg/Shield';
import { Bank } from '../../Components/svg/Bank';
import React from 'react';

const PhoneScreenContent = () => (
  <>
    <Title>
      <span style={{ color: 'black' }}>Use non-custodial</span> crypto wallet
    </Title>
    <Paragraph>
      Get and delete your seed phrase from our server for individual owning of
      wallet.
    </Paragraph>
    <Table bigGap>
      <Advantages>
        <AdvantagesWallet />
        <AdvantagesText>Transparent use of the crypto wallet</AdvantagesText>
      </Advantages>
      <Advantages>
        <Shield />
        <AdvantagesText>Secure and trusted solutions</AdvantagesText>
      </Advantages>
      <Advantages>
        <Bank />
        <AdvantagesText>Regulated crypto operations</AdvantagesText>
      </Advantages>
    </Table>
  </>
);

export default PhoneScreenContent;
