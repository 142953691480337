import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInputVendor from 'react-phone-number-input';
import styled from 'styled-components';
import { mobileBreakpoint } from '../../utils/constants';

const StyledInput = styled(PhoneInputVendor)<{ error?: boolean }>`
  padding: 10.5px 0;
  border-bottom: 1px solid;
  border-color: ${(props) => (props.error ? '#ED0A34' : 'rgba(0,0,0,0.1)')};

  & .PhoneInputCountry {
    width: 48px;
    height: 48px;
    margin: 0;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none !important;
    
    & .PhoneInputCountrySelectArrow {
      display: none;
    }
  }
  
  & .PhoneInputCountryIconImg {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    border-radius: 50%;
    outline: none;
    border: none;
  }

  & .PhoneInputCountryIcon {
    width: 24px;
    height: 24px;
  }
  
  & .PhoneInputCountryIcon--border {
    box-shadow: none;
    background: none;
  }
  

  & .PhoneInputInput {
    border: none;
    color: ${(props) => (props.error ? '#ED0A34' : '#000')};
    font-size: 18px;
    line-height: 24px;
    padding-left: 12px;

    &::placeholder {
      color: ${(props) => (props.error ? '#ED0A34' : '#616161')};
    }

    &:focus-visible {
      outline: none;
    }
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    padding: 11.5px 0;
  }
`;

const LabelWrapper = styled.div<{ error?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.error ? '#ED0A34' : '#616161')};
`;

type Props = {
  onChange: (value: string) => void;
  value?: string;
  helperText?: string;
  isError?: boolean;
};

export const PhoneInput = ({ onChange, value, helperText, isError }: Props) => {
  const [lastValue, setLastValue] = useState(value);

  const handleChange = (e: string) => {
    if (value && value?.length >= 1 && typeof e) {
      setLastValue(value);
    }
    onChange(e);
  };

  return (
    <div>
      <LabelWrapper error={isError}>Phone number</LabelWrapper>
      <StyledInput
        limitMaxLength
        autoFocus
        error={isError}
        value={value || lastValue}
        international
        placeholder="Enter phone number"
        onChange={handleChange}
        data-test-id="phone-input"
      />
      <LabelWrapper error={isError}>{helperText}</LabelWrapper>
    </div>
  );
};
