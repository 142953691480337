import React from 'react';
import styled from "styled-components";
import { mobileBreakpoint } from '../../../utils/constants';

const StyledSVG = styled.svg`
  width: 76px;
  height: 76px;

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 31px;
    height: 31px;
    padding: 5px;
    
    border-radius: 50%;
    border: 19.5px solid rgba(239, 250, 240, 1);
    
    background: rgba(216, 243, 218, 1);
  }
`
export const CompleteIcon = () => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
    >
      <path
        d="M38 75.5C17.2888 75.5 0.5 58.7113 0.5 38C0.5 17.2888 17.2888 0.5 38 0.5C58.7113 0.5 75.5 17.2888 75.5 38C75.5 58.7113 58.7113 75.5 38 75.5ZM34.2612 53L60.7738 26.4837L55.4713 21.1812L34.2612 42.395L23.6525 31.7863L18.35 37.0887L34.2612 53Z"
        fill="#58CB4E"
      />
    </StyledSVG>
  );
};

