import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WidgetWrapper } from '../../Components/WidgetWrapper';
import { OTPInput } from '../../Components/OTPInput';
import { authenticate } from '../../../services/api';
import { storageApi } from '../../../services/storageApi';

const OTP_LENGTH = 10;

export function OTPInputScreen({
  onSubmit,
  phone,
  onClickBackArrow,
}: {
  onSubmit: () => void;
  phone: string;
  onClickBackArrow: () => void;
}) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const wasAttempt = useRef(false);

  const handleChange = useCallback(
    (e: string) => {
      if (wasAttempt.current) {
        wasAttempt.current = false;
      }

      if (error) {
        setError('');
      }

      setCode(e);
    },
    [error, wasAttempt]
  );

  const handleSendOTP = useCallback(() => {
    setBtnLoading(true);
    authenticate(code)
      .then((res) => {
        const token = res.data.token;
        onSubmit();
        storageApi.token.set(token);
      })
      .catch((err) => setError(err.response.data.message))
      .finally(() => setBtnLoading(false));
  }, [setBtnLoading, code, onSubmit]);

  useEffect(() => {
    if (code.length === OTP_LENGTH && !wasAttempt.current) {
      handleSendOTP();
      wasAttempt.current = true;
    }
  }, [wasAttempt, code, handleSendOTP]);

  return (
    <WidgetWrapper
      footerButtonLabel="Next"
      footerButtonLoading={btnLoading}
      onClickFooterButton={handleSendOTP}
      disableBtn={code.length !== OTP_LENGTH || btnLoading}
      title="Enter code from SMS"
      text={`We have sent you an sms with a code to number ${phone}`}
      onClickArrow={onClickBackArrow}
    >
      <OTPInput
        isError={Boolean(error)}
        helperText={error}
        otpLength={OTP_LENGTH}
        value={code}
        onChange={handleChange}
      />
    </WidgetWrapper>
  );
}
