import styled from 'styled-components';
import { mobileBreakpoint, tabletBreakpoint } from "../../../utils/constants";

export const OutsideWrapper = styled.div`
  width: calc(100% - 80px*2);
  min-height: calc(100vh - 16px*2);
  margin: 16px auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: Inter, Arial, sans-serif;
  font-weight: 400;

  @media screen and (max-width: ${tabletBreakpoint}) {
    width: calc(100% - 40px*2);
    margin-top: 11px;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: calc(100% - 24px*2);
  }
`;

export const Wrapper = styled.div`
  width: calc(100% - 80px*2);
  max-width: 1440px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  @media screen and (max-width: ${tabletBreakpoint}) {
    width: 100%;
    margin-top: 76px;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    margin-top: 0;
  }
`;

export const WidgetArea = styled.div<{ bigGap?: boolean }>`
  position: absolute;
  z-index: 10;
  left: calc(100% - 400px);
  background: white;

  @media screen and (max-width: ${tabletBreakpoint}) {
    left: calc(50% - 200px);
    top: ${(props) => (props.bigGap ? '249px' : '207px')};
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    position: static;
    margin: 75px auto 0 auto;
  }
`;

export const Table = styled.div<{ bigGap?: boolean }>`
  margin: 40px 0 ${(props) => (props.bigGap ? '94px' : '74px')} 0;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: ${tabletBreakpoint}) {
    margin: 808px auto 48px 0;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    margin: 32px 0;
  }
`;

export const Advantages = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: ${tabletBreakpoint}) {
    align-items: start;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${tabletBreakpoint}) {
    margin: 64px 0 0 0;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    flex-direction: column-reverse;
    gap: 32px;
  }
`;

export const BlurGradient = styled.div`
  width: 304px;
  height: 304px;
  position: absolute;
  z-index: 1;
  top: 200px;
  left: calc(100% - 552px);
  transform: rotate(45deg);

  background: linear-gradient(1.35deg, #ffe4b3 10.44%, #fef4f1 124.12%);
  filter: blur(200px);

  @media screen and (max-width: ${tabletBreakpoint}) {
    display: none;
  }
`;
