import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ShowSeedPhrases } from './ShowSeedPhrases';
import { CheckSeedPhrases } from './CheckSeedPhrases';
import { ShowComplete } from './ShowComplete';
import { getWalletsInfo } from '../../../../services/api';
import { ShowError } from './ShowError';
import { clearStorage } from '../../../../services/storageApi';
import { AxiosError } from 'axios';
enum Screens {
  SHOW_SEED_PHRASES,
  CHECK_SEED_PHRASES,
  COMPLETE,
}

export const SecretSeedPhraseScreen = () => {
  const [screen, setScreen] = useState(Screens.SHOW_SEED_PHRASES);

  const { data, isLoading, isError, refetch, errorUpdateCount } = useQuery(
    'data',
    getWalletsInfo,
    {
      retry: false,
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          clearStorage();
          window.location.reload();
        }
      },
    }
  );

  const incrementScreen = useCallback(() => {
    setScreen((screen) => {
      return screen + 1;
    });
  }, []);

  const decrementScreen = useCallback(() => {
    setScreen((screen) => screen - 1);
  }, []);

  const seedPhrases = useMemo(() => {
    if (!data) {
      return;
    }

    return data.seed.split(' ');
  }, [data]);

  const currentScreen = useCallback(() => {
    if (isError) {
      return (
        <ShowError errorUpdateCount={errorUpdateCount} onRefetch={refetch} />
      );
    }

    switch (screen) {
      case Screens.SHOW_SEED_PHRASES:
        return (
          <ShowSeedPhrases
            seedPhrases={seedPhrases}
            onCLickDeleteButton={incrementScreen}
            seedPhrasesLoading={isLoading}
          />
        );
      case Screens.CHECK_SEED_PHRASES:
        if (!data) {
          return (
            <ShowError
              errorUpdateCount={errorUpdateCount}
              onRefetch={refetch}
            />
          );
        }

        return (
          <CheckSeedPhrases
            seedPhraseId={data.id}
            onDeleteSuccess={incrementScreen}
            onDeleteFailed={decrementScreen}
            seedPhrases={seedPhrases}
            onClickArrow={decrementScreen}
          />
        );
      case Screens.COMPLETE:
        return <ShowComplete />;
    }
  }, [
    screen,
    isError,
    refetch,
    seedPhrases,
    isLoading,
    incrementScreen,
    decrementScreen,
    data,
    errorUpdateCount,
  ]);

  return <>{currentScreen()}</>;
};
