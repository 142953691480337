import React from 'react';

export const AdvantagesWallet = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.85 3.95V7.75H13.35V3.95C13.35 3.68 13.11 3.55 12.95 3.55C12.9 3.55 12.85 3.56 12.8 3.58L4.87 6.57C4.34 6.77 4 7.27 4 7.84V8.51C3.09 9.19 2.5 10.28 2.5 11.51V7.84C2.5 6.65 3.23 5.59 4.34 5.17L12.28 2.17C12.5 2.09 12.73 2.05 12.95 2.05C13.95 2.05 14.85 2.86 14.85 3.95Z"
      fill="#292D32"
    />
    <path
      d="M21.5 14.5V15.5C21.5 15.77 21.29 15.99 21.01 16H19.55C19.02 16 18.54 15.61 18.5 15.09C18.47 14.78 18.59 14.49 18.79 14.29C18.97 14.1 19.22 14 19.49 14H21C21.29 14.01 21.5 14.23 21.5 14.5Z"
      fill="#292D32"
    />
    <path
      d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V18.24C2.5 20.31 4.19 22 6.26 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.29 14.77 17.54 14.04 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
      fill="#292D32"
    />
  </svg>
);
