import React from 'react';
import styled from 'styled-components';
import { TrashIcon } from '../svg/TrashIcon';
import { mobileBreakpoint } from "../../../utils/constants";

const DeleteButtonWrapper = styled.button`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  transform: translate(-32px, 0);
  bottom: 136px;
  
  background: transparent;
  border: none;
  
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    transform: translate(0, 0);
    bottom: 96px;
  }
`;

const Label = styled.p`
  margin: 0;
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

type DeleteButtonProps = {
  label: string;
  onClick: () => void;
};
const DeleteButton = ({ label, onClick }: DeleteButtonProps) => {
  return (
    <DeleteButtonWrapper onClick={onClick}>
      <TrashIcon />
      <Label>{label}</Label>
    </DeleteButtonWrapper>
  );
};

export default DeleteButton;
