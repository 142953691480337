import { httpClient } from './httpClient';
import { clearStorage, storageApi } from './storageApi';

export const createSession = async (phone: string) => {
  return httpClient.post('/createSession', { phone }).then((res) => {
    storageApi.sessionId.set(res.data.sessionId);
    return res;
  });
};

export const authenticate = (code: string) => {
  const sessionId = storageApi.sessionId.get();
  return httpClient.post<{ token: string }>('/authenticate', {
    code,
    sessionId,
  });
};

export const getWalletsInfo = async () => {
  const res = await httpClient.get<{
    addresses: Array<Address>;
    id: string;
    seed: string;
  }>('');

  return res.data;
};

export const deleteWalletFromServer = (walletId: string) => {
  return httpClient.delete(`/${walletId}`).then((res) => {
    clearStorage();
    return res;
  });
};

export type Address = {
  address: string;
  blockchainName: string;
  id: string;
  privateKey: string;
};
