import React from 'react';
import styled from 'styled-components';
import { mobileBreakpoint } from '../../../utils/constants';

const StyledSVG = styled.svg`
  width: 90px;
  height: 90px;

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 40px;
    height: 40px;
    padding: 10px;

    border-radius: 50%;
    border: 10px solid rgba(254, 235, 239, 1);

    background: rgba(253, 206, 215, 1);
  }
`;
export const ErrorIcon = () => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 100"
      fill="none"
    >
      <path
        d="M48.2472 11.2498L83.9697 73.1248C84.2989 73.6949 84.4721 74.3416 84.4722 74.9998C84.4722 75.6581 84.2989 76.3047 83.9698 76.8748C83.6406 77.4449 83.1673 77.9183 82.5972 78.2474C82.0272 78.5765 81.3805 78.7498 80.7222 78.7498H9.27725C8.61899 78.7498 7.97234 78.5765 7.40228 78.2474C6.83222 77.9183 6.35884 77.4449 6.02972 76.8748C5.7006 76.3047 5.52734 75.6581 5.52734 74.9998C5.52735 74.3416 5.70062 73.6949 6.02975 73.1248L41.7522 11.2498C42.0814 10.6798 42.5548 10.2065 43.1248 9.87736C43.6949 9.54826 44.3415 9.375 44.9997 9.375C45.658 9.375 46.3046 9.54826 46.8747 9.87736C47.4447 10.2065 47.9181 10.6798 48.2472 11.2498ZM41.2497 59.9998V67.4998H48.7497V59.9998H41.2497ZM41.2497 33.7498V52.4998H48.7497V33.7498H41.2497Z"
        fill="#ED0A34"
      />
    </StyledSVG>
  );
};
