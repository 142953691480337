import axios, { AxiosRequestConfig } from 'axios';
import { clearStorage, storageApi } from "./storageApi";

const API_PREFIX = `userWallets`;

const httpClientInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/${API_PREFIX}`,
});

const handleRequest = (config: AxiosRequestConfig<unknown>) => {
  const token = storageApi.token.get();

  if (token) {
    return { ...config, headers: { ...config.headers, Authorization: token } };
  }

  return config;
};

httpClientInstance.interceptors.request.use(handleRequest);

const handleResponseError = (err: unknown) => {
  const errorObject = JSON.parse(JSON.stringify(err));

  if (errorObject.status === 401 || errorObject.status === 404) {
    clearStorage();

    // todo: Нехорошо из слоя апи управлять перезагрузкой.
    if (errorObject.status === 401) {
      window.location.reload();
    }
  }

  throw err;
};
const handleResponse = (res: unknown) => {
  return res;
};

httpClientInstance.interceptors.response.use(
  handleResponse,
  handleResponseError
);

export { httpClientInstance as httpClient };
