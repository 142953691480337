import React, { useCallback, useEffect, useState } from 'react';
import { storageApi } from '../../services/storageApi';
import { PhoneInputScreen } from './Screens/PhoneInputScreen';
import { OTPInputScreen } from './Screens/OTPInputScreen';
import { SecretSeedPhraseScreen } from './Screens/SeedPhrasesScreen';

type Props = {
  onAuth: () => void;
};

export const Widget = ({ onAuth }: Props) => {
  const [isAuthTokenExist, setIsAuthTokenExist] = useState<Boolean>(
    Boolean(storageApi.token.get())
  );
  const [phone, setPhone] = useState('');

  const handlePhoneSent = useCallback(({ phone }: { phone: string }) => {
    setPhone(phone);
  }, []);

  const handleOtpSent = useCallback(() => {
    setIsAuthTokenExist(true);
  }, []);

  const resetPhone = useCallback(() => {
    setPhone('');
  }, []);

  useEffect(() => {
    if (isAuthTokenExist) {
      onAuth();
    }
  }, [isAuthTokenExist, onAuth]);

  if (isAuthTokenExist) {
    return <SecretSeedPhraseScreen />;
  }

  if (phone) {
    return (
      <OTPInputScreen
        phone={phone}
        onSubmit={handleOtpSent}
        onClickBackArrow={resetPhone}
      />
    );
  }

  return <PhoneInputScreen onSuccess={handlePhoneSent} />;
};
