import React, { useCallback, useState } from 'react';
import { Layout, ContentType } from './Layout';
import { Widget } from './Widget';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export const App = () => {
  const [currentContent, setContent] = useState<ContentType>('PHONE_CONTENT');

  const setSeedContent = useCallback(() => {
    setContent('SEED_PHRASE_CONTENT');
  }, []);

  return (
    <Layout content={currentContent}>
      <QueryClientProvider client={queryClient}>
        <Widget onAuth={setSeedContent} />
      </QueryClientProvider>
    </Layout>
  );
};
