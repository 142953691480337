import styled from 'styled-components';
import { mobileBreakpoint } from '../../../../utils/constants';
export const TableSeedPhrases = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 20px;

  @media screen and (max-width: ${mobileBreakpoint}) {
    display: grid;
    grid-template-columns: repeat(2, 100px);
    justify-content: space-around;
    column-gap: 72px;
  }
`;
export const StateWrapper = styled.div`
  width: 100%;
  margin-top: 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${mobileBreakpoint}) {
    margin-top: 0;
  }
`;
