import React, { PropsWithChildren } from 'react';
import { Button } from './WidgetButton';
import { BackArrow } from './BackArrow';
import { TextWidget, TitleWidget } from '../Common/Typography';
import { Body, Footer, Header, Wrapper } from './WidgetWrapperStyledComponents';

export const WidgetWrapper = ({
  onClickFooterButton,
  footerButtonLabel,
  footerButtonLoading,
  children,
  disableBtn,
  title,
  text,
  onClickArrow,
}: PropsWithChildren<{
  onClickFooterButton: () => void;
  footerButtonLabel: string;
  footerButtonLoading?: boolean;
  title?: string;
  text?: string;
  disableBtn?: boolean;
  onClickArrow?: () => void;
}>) => {
  return (
    <Wrapper>
      <Header style={{ margin: `${title ? '' : 0}` }}>
        {onClickArrow && <BackArrow onClickArrow={onClickArrow} />}
        <TitleWidget>{title}</TitleWidget>
      </Header>
      <TextWidget style={{ margin: `${text ? '' : 0}` }}>{text}</TextWidget>
      <Body>{children}</Body>
      <Footer>
        <Button
          label={footerButtonLabel}
          onClick={onClickFooterButton}
          disabled={disableBtn}
          loading={footerButtonLoading}
        />
      </Footer>
    </Wrapper>
  );
};
