import React from 'react';
import styled from 'styled-components';
import { mobileBreakpoint } from '../../../utils/constants';
import { LoaderSpinner } from '../Common/LoaderSpinner';

const StyledButton = styled.button<{ variant: 'primary' | 'secondary' }>`
  padding: 20px 32px;
  border-radius: 8px;
  background: black;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  flex: 1;
  gap: 12px;
  justify-content: center;
  border: none;
  justify-self: end;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    border: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
  }

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    margin-top: 120px;
    padding: 20px 0;
  }
`;

type Props = {
  label: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  className?: string;
  dataTestId?: string;
};

export const Button = ({
  label,
  loading,
  onClick,
  variant = 'primary',
  disabled,
  className,
  dataTestId,
}: Props) => {
  return (
    <StyledButton
      className={className}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      data-test-id={dataTestId}
    >
      {label}
      {loading && <LoaderSpinner />}
    </StyledButton>
  );
};
