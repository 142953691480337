import React, { useCallback } from 'react';
import { WidgetWrapper } from '../../../Components/WidgetWrapper';
import { ErrorIcon } from '../../../Components/svg/ErrorIcon';
import { StateWrapper } from './SeedPhrasesStyleComponents';
import { StateText, StateTitle } from '../../../Components/Common/Typography';
import { clearStorage } from '../../../../services/storageApi';
import { QueryObserverResult } from 'react-query/types/core/types';

type ShowErrorProps = {
  onRefetch: () => Promise<QueryObserverResult>;
  errorUpdateCount?: number;
};
const MAX_REFETCH_ATTEMPTS = 3;

export const ShowError = ({ errorUpdateCount, onRefetch }: ShowErrorProps) => {
  const handleReload = useCallback(() => {
    if (errorUpdateCount && errorUpdateCount >= MAX_REFETCH_ATTEMPTS) {
      clearStorage();
      window.location.reload();
      return;
    }

    onRefetch();
  }, [errorUpdateCount, onRefetch]);

  return (
    <WidgetWrapper
      onClickFooterButton={handleReload}
      footerButtonLabel="Try again"
    >
      <StateWrapper>
        <ErrorIcon />
        <StateTitle>Something went wrong</StateTitle>
        <StateText>Please try again</StateText>
      </StateWrapper>
    </WidgetWrapper>
  );
};
