import styled from 'styled-components';
import { mobileBreakpoint } from '../../../utils/constants';

export const Wrapper = styled.div`
  width: 400px;
  max-width: 400px;
  min-height: 568px;

  padding: 40px 0;

  display: flex;
  flex-direction: column;

  border-radius: 24px;

  position: relative;

  box-shadow: 0 1.2px 40px rgba(189, 191, 194, 0.2);

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    max-width: 100%;
    min-height: auto;
    padding: 0;
    box-shadow: none;
  }
`;

export const Header = styled.div`
  margin: 0 0 16px 0;
  padding: 0 32px;
  display: flex;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: ${mobileBreakpoint}) {
    align-items: start;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
`;

export const Body = styled.div`
  padding: 0 32px 32px;
  height: 300px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    height: auto;
    padding: 0;
  }
`;

export const Footer = styled.div`
  margin: auto 0 0 0;
  padding: 0 32px;
  display: flex;

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    padding: 0;
  }
`;
