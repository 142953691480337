import React, { useCallback, useMemo, useState } from "react";
import { WidgetWrapper } from '../../../Components/WidgetWrapper';
import SeedPhraseInput from '../../../Components/SeedPhraseInput';
import { TableSeedPhrases } from './SeedPhrasesStyleComponents';
import { deleteWalletFromServer } from '../../../../services/api';

const SEED_PHRASE_LENGTH = 12;

const initialState = Array(SEED_PHRASE_LENGTH).fill('');

type CheckSeedPhrasesProps = {
  onClickArrow: () => void;
  seedPhrases: Array<string> | undefined;
  onDeleteSuccess: () => void;
  onDeleteFailed: () => void;
  seedPhraseId: string;
};
export const CheckSeedPhrases = ({
  onClickArrow,
  seedPhrases,
  onDeleteFailed,
  onDeleteSuccess,
  seedPhraseId,
}: CheckSeedPhrasesProps) => {
  const [inProcessButton, setInProcessButton] = useState(false);
  const [userValue, setUserValue] = useState<Array<string>>(initialState);

  const handleDelete = useCallback(() => {
    setInProcessButton(true);
    deleteWalletFromServer(seedPhraseId)
      .then(() => {
        onDeleteSuccess();
      })
      .catch(() => {
        onDeleteFailed();
      })
      .finally(() => {
        setInProcessButton(false);
      });
  }, [seedPhraseId, onDeleteFailed, onDeleteSuccess]);

  const checkButtonState = useMemo(() => {
    return JSON.stringify(seedPhrases) !== JSON.stringify(userValue);
  }, [seedPhrases, userValue]);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;

      setUserValue((currentValue) => {
        const newValue = [...currentValue];
        newValue[Number(target.name) - 1] = target.value.trim();
        return newValue;
      });
    },
    []
  );

  return (
    <WidgetWrapper
      onClickFooterButton={handleDelete}
      footerButtonLabel="Confirm delete"
      title="Delete seed phrase"
      text="Confirm your seed phrase so we can delete it from our server. After deleting it, it cannot be restored."
      onClickArrow={onClickArrow}
      disableBtn={checkButtonState || inProcessButton}
      footerButtonLoading={inProcessButton}
    >
      <TableSeedPhrases>
        {userValue.map((phrase, index) => {
          return (
            <SeedPhraseInput
              key={index + 1}
              number={index + 1}
              onChange={onChangeInput}
            />
          );
        })}
      </TableSeedPhrases>
    </WidgetWrapper>
  );
};
