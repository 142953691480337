import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 32px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
`;
export const BackArrow = ({ onClickArrow }: { onClickArrow?: () => void }) => (
  <StyledSVG
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    onClick={onClickArrow}
  >
    <g clipPath="url(#clip0_411_16546)">
      <path
        d="M20.546 9.88L18.666 8L10.666 16L18.666 24L20.546 22.12L14.4393 16L20.546 9.88Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_411_16546">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </StyledSVG>
);