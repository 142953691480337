import React, { useCallback, useMemo } from 'react';
import OtpInputVendor from '../../vendors/ReactOtpInput';
import styled from 'styled-components';

type Props = {
  value: string;
  onChange: (value: string) => void;
  helperText?: string;
  isError?: boolean;
  otpLength: number;
};

const HelperTextLabel = styled.div<{ error?: boolean }>`
  margin-top: 20px;
  color: ${(props) => (props.error ? '#ED0A34' : '#616161')};
  font-size: 16px;
  line-height: 20px;
`;

export const OTPInput = ({
  value,
  onChange,
  isError,
  helperText,
  otpLength,
}: Props) => {
  const handleChange = useCallback(
    (otp: string) => {
      onChange(otp);
    },
    [onChange]
  );

  const placeholder = useMemo(() => {
    return Array.from({ length: otpLength }, () => '0').join('');
  }, [otpLength]);

  return (
    <div>
      <OtpInputVendor
        shouldAutoFocus
        placeholder={placeholder}
        isError={isError}
        value={value}
        numInputs={otpLength}
        onChange={handleChange}
      />
      <HelperTextLabel error={isError}>{helperText}</HelperTextLabel>
    </div>
  );
};
