import React from 'react';
import styled from 'styled-components';

const SkeletonInput = styled.div`
  width: 100px;
  height: 46px;
  border-radius: 8px;
  background-image: linear-gradient(
    90deg,
    #9d9c9c 0px,
    #b9b9b9 40px,
    #9d9c9c 80px
  );

  animation: shine-loading-image 2.22s infinite linear;
  @keyframes shine-loading-image {
    0% {
      background-position: -32px;
    }
    40%,
    100% {
      background-position: 208px;
    }
  }
`;

const InputRow = styled.div`
  width: 100px;
  border-bottom: 1px solid rgba(229, 229, 230, 1);
  position: relative;
  height: 46px;
  display: flex;
  align-items: center;
`;

const PhraseNumber = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

const PhraseInput = styled.input`
  --left-padding: 24px;

  width: calc(100% - var(--left-padding));
  margin-left: var(--left-padding);
  border: none;
  color: black;

  font-family: 'Inter', 'Arial', sans-serif;
  font-size: 18px;
  line-height: 24px;

  overflow: auto;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

type SeedPhraseInputProps = {
  number: number;
  text?: string | undefined;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
};
const SeedPhraseInput = ({
  number,
  text,
  readOnly,
  onChange,
  isLoading,
}: SeedPhraseInputProps) => {
  return (
    <>
      {isLoading ? (
        <SkeletonInput />
      ) : (
        <InputRow>
          <PhraseNumber>{number}.</PhraseNumber>
          <PhraseInput
            name={number.toString()}
            readOnly={readOnly}
            value={text}
            onChange={onChange}
          ></PhraseInput>
        </InputRow>
      )}
    </>
  );
};

export default SeedPhraseInput;
