import React from 'react';
import styled from 'styled-components';
import { tabletBreakpoint } from '../../../utils/constants';

const SVGLogo = styled.svg`
  width: 148px;
  height: 40px;

  @media screen and (max-width: ${tabletBreakpoint}) {
    width: 120px;
    height: 32px;
  }
`;

export const SwipeluxLogo = () => {
  return (
    <SVGLogo>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148 40" fill="none">
        <g clipPath="url(#clip0_433_2530)">
          <path
            d="M45.0566 22.2409C45.0566 24.5272 46.9444 26.1117 49.9467 26.1117C52.9036 26.1117 54.9733 24.6403 54.9733 22.2183C54.9733 20.4074 53.9498 19.5473 52.0847 19.1398L49.9695 18.6871C49.1734 18.506 48.7413 18.2118 48.7413 17.6911C48.7413 17.08 49.2417 16.6725 50.0377 16.6725C50.8793 16.6725 51.3569 17.1932 51.3569 17.9628H54.6776C54.6776 15.6766 52.8353 14.1373 50.1969 14.1373C47.4676 14.1373 45.4433 15.4955 45.4433 17.8496C45.4433 19.8415 46.4668 20.747 48.5593 21.1997L50.6518 21.6524C51.4934 21.8335 51.6753 22.1957 51.6753 22.5352C51.6753 23.1237 51.175 23.5086 50.0832 23.5086C49.0142 23.5086 48.4229 23.0332 48.3774 22.2409H45.0566Z"
            fill="#050505"
          />
          <path
            d="M59.0405 25.8174H62.4522L63.544 22.2862C64.2718 19.9095 64.4765 19.0267 64.5675 18.5513C64.6357 19.0267 64.9314 20.3622 65.5 22.2183L66.5918 25.8174H69.8442L73.8246 14.4995H70.1172L69.0254 18.1891C68.9117 18.5966 68.3658 20.5885 68.2294 21.4034C68.1157 20.6791 67.5698 18.5739 67.4561 18.1891L66.3871 14.4995H62.7934L61.6334 18.1891C61.133 19.7736 61.0193 20.2037 60.8146 21.4034C60.6327 20.2716 60.3825 19.1625 60.1095 18.1891L59.0633 14.4995H55.3786L59.0405 25.8174Z"
            fill="#050505"
          />
          <path
            d="M77.101 12.7339C78.1928 12.7339 79.0798 11.8511 79.0798 10.742C79.0798 9.6328 78.1928 8.77264 77.101 8.77264C75.9865 8.77264 75.0995 9.6328 75.0995 10.742C75.0995 11.8511 75.9865 12.7339 77.101 12.7339ZM75.3724 25.8174H78.8751V14.4995H75.3724V25.8174Z"
            fill="#050505"
          />
          <path
            d="M81.5028 31.25H85.0055V24.6856C85.6651 25.5458 87.1208 26.1343 88.6902 26.1343C92.0791 26.1343 94.0352 23.5538 94.0352 19.9774C94.0352 16.333 91.8289 14.1373 88.6674 14.1373C87.0525 14.1373 85.6196 14.839 84.9828 15.8803L84.8008 14.4995H81.5028V31.25ZM85.0283 20.1585C85.0283 18.4608 86.1655 17.329 87.8031 17.329C89.4635 17.329 90.5097 18.4834 90.5097 20.1585C90.5097 21.8335 89.4635 22.9879 87.8031 22.9879C86.1655 22.9879 85.0283 21.8561 85.0283 20.1585Z"
            fill="#050505"
          />
          <path
            d="M101.328 26.1117C104.444 26.1117 106.696 24.5045 107.105 21.992H103.898C103.648 22.8068 102.716 23.2822 101.396 23.2822C99.8498 23.2822 99.0082 22.5805 98.8035 21.0865L107.06 21.0412V20.1585C107.06 16.4688 104.831 14.1373 101.26 14.1373C97.8255 14.1373 95.4373 16.6046 95.4373 20.1358C95.4373 23.6217 97.8938 26.1117 101.328 26.1117ZM101.283 16.9668C102.693 16.9668 103.58 17.7364 103.58 18.9361H98.849C99.122 17.578 99.8953 16.9668 101.283 16.9668Z"
            fill="#050505"
          />
          <path
            d="M112.637 25.8174V8.75H109.134V25.8174H112.637Z"
            fill="#050505"
          />
          <path
            d="M122.816 14.4995V20.3848C122.816 22.2409 122.202 23.0106 120.7 23.0106C119.268 23.0106 118.631 22.1957 118.631 20.3395V14.4995H115.128V21.6071C115.128 24.3913 116.72 26.1117 119.313 26.1117C120.678 26.1117 122.088 25.4779 122.793 24.5498L123.02 25.8174H126.318V14.4995H122.816Z"
            fill="#050505"
          />
          <path
            d="M131.835 25.8174L133.746 22.5578L135.702 25.8174H139.728L136.111 20.2716L139.864 14.4995H135.952L133.996 17.9401L131.904 14.4995H127.855L131.676 20.3395L127.855 25.8174H131.835Z"
            fill="#050505"
          />
          <g clipPath="url(#clip1_433_2530)">
            <path
              d="M0.892536 21.5282C0.129571 20.7662 0.129571 19.5308 0.892536 18.7688L10.9082 8.76583C11.6712 8.00384 12.9082 8.00384 13.6711 8.76583L23.6868 18.7688C24.4498 19.5308 24.4498 20.7663 23.6868 21.5282L13.6711 31.5312C12.9082 32.2932 11.6712 32.2932 10.9082 31.5312L0.892536 21.5282Z"
              fill="#F4663E"
            />
            <path
              d="M17.2685 28.6288C16.8871 29.0098 16.8871 29.6276 17.2685 30.0086L18.7931 31.5312C19.5561 32.2932 20.7931 32.2932 21.5561 31.5312L31.5717 21.5282C32.3347 20.7663 32.3347 19.5308 31.5717 18.7688L21.5561 8.76583C20.7931 8.00384 19.5561 8.00384 18.7931 8.76583L17.2685 10.2885C16.8871 10.6695 16.8871 11.2872 17.2685 11.6682L24.3781 18.7688C25.1411 19.5308 25.1411 20.7662 24.3781 21.5282L17.2685 28.6288Z"
              fill="#FA9B51"
            />
            <path
              d="M25.1533 28.629C24.7718 29.01 24.7718 29.6277 25.1533 30.0087L26.6778 31.5312C27.4407 32.2932 28.6777 32.2932 29.4407 31.5312L39.4564 21.5282C40.2193 20.7663 40.2193 19.5308 39.4564 18.7688L29.4407 8.76583C28.6777 8.00384 27.4407 8.00384 26.6778 8.76583L25.1533 10.2884C24.7718 10.6694 24.7718 11.2871 25.1533 11.6681L32.263 18.7688C33.026 19.5308 33.026 20.7662 32.263 21.5282L25.1533 28.629Z"
              fill="#FFDA99"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_433_2530">
            <rect width="148" height="40" fill="white" />
          </clipPath>
          <clipPath id="clip1_433_2530">
            <rect width="40.0507" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SVGLogo>
  );
};
