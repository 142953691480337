import styled from 'styled-components';
import { mobileBreakpoint } from '../../../utils/constants';
export const TextWidget = styled.p`
  padding: 0 32px;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin: 0 0 40px 0;

  @media screen and (max-width: ${mobileBreakpoint}) {
    padding: 0;
    margin-bottom: 48px;
  }
`;
export const TitleWidget = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @media screen and (max-width: ${mobileBreakpoint}) {
    font-size: 32px;
    line-height: 46px;
  }
`;
export const StateTitle = styled.h2`
  width: 80%;
  margin: 40px 0 16px 0;
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media screen and (max-width: ${mobileBreakpoint}) {
    width: 100%;
    font-family: 'Satoshi', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin-top: 24px;
  }
`;
export const StateText = styled.p`
  margin: 0;
  color: rgba(97, 97, 97, 1);
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
