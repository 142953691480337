import React, { PropsWithChildren } from 'react';
import { SwipeluxLogo } from '../Components/Common/SwipeluxLogo';
import {
  Copyright,
  FooterLink,
  Note,
} from './StyledComponents/WrapperTypography';
import {
  BlurGradient,
  FooterRow,
  OutsideWrapper,
  WidgetArea,
  Wrapper,
} from './StyledComponents/WrapperStyledComponents';
import PhoneScreenContent from './Contents/PhoneScreenContent';
import SeedScreenContent from './Contents/SeedScreenContent';
import { linkContactUS } from '../../utils/constants';

export type ContentType = 'PHONE_CONTENT' | 'SEED_PHRASE_CONTENT';

export const Layout = ({
  content,
  children,
}: PropsWithChildren<{ content: ContentType }>) => (
  <OutsideWrapper>
    <SwipeluxLogo />
    <Wrapper>
      <WidgetArea bigGap={content === 'PHONE_CONTENT'}>{children}</WidgetArea>

      {content === 'PHONE_CONTENT' && <PhoneScreenContent />}

      {content === 'SEED_PHRASE_CONTENT' && <SeedScreenContent />}

      <BlurGradient />
      <Note>
        <span style={{ fontWeight: 600 }}>Note:</span> Уour seed phrase is
        stored with us until you delete it. If you lose your Secret Recovery
        Phrase, Swipelux can’t help you recover your wallet. Never give you
        Secret Recovery Phrase or your private key(s) to anyone or any site,
        unless you want them to have full control over your funds.
      </Note>
    </Wrapper>
    <FooterRow>
      <Copyright>Copyright © 2023 Swipelux OÜ. All rights reserved.</Copyright>
      <FooterLink target="_blank" href={linkContactUS} aria-label="email">
        Contact Us
      </FooterLink>
    </FooterRow>
  </OutsideWrapper>
);
