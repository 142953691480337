import React, { useCallback, useMemo, useState } from 'react';
import { WidgetWrapper } from '../../Components/WidgetWrapper';
import { PhoneInput } from '../../Components/PhoneInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createSession } from '../../../services/api';
import { PHONE_QUERY_KEY } from '../../../utils/constants';

const MAX_PHONE_LENGTH = 17;
const MIN_PHONE_LENGTH = 10;

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .required()
    .test(
      'phone check',
      "That doesn't look like a phone number",
      (v: string | undefined) => {
        return !!v && v.length <= MAX_PHONE_LENGTH && v.length > 4;
      }
    ),
});

const phoneFromUrl = new URLSearchParams(window.location.search).get(
  PHONE_QUERY_KEY
);
const defaultPhone = phoneFromUrl ? `+${phoneFromUrl}` : '';

export function PhoneInputScreen({
  onSuccess,
}: {
  onSuccess: (result: { phone: string }) => void;
}) {
  const [networkError, setNetworkError] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  const onSubmit = useCallback(
    ({ phone }: { phone: string }) => {
      setButtonLoading(true);
      createSession(phone)
        .then(() => {
          onSuccess({ phone });
        })
        .catch((err) => {
          setNetworkError(err.response.data.message);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    },
    [onSuccess]
  );

  const { handleSubmit, values, errors, setFieldValue } = useFormik({
    initialValues: {
      phone: defaultPhone,
    },
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleChange = useCallback(
    (e: string) => {
      setNetworkError('');
      setFieldValue('phone', e);
    },
    [setNetworkError, setFieldValue]
  );

  const checkInputLength = useMemo(
    () => (values.phone ? values.phone.length < MIN_PHONE_LENGTH : true),
    [values.phone]
  );

  return (
    <WidgetWrapper
      onClickFooterButton={handleSubmit}
      footerButtonLabel="Next"
      disableBtn={checkInputLength || buttonLoading}
      footerButtonLoading={buttonLoading}
      title="Enter your phone number"
      text="To use the wallet, you need to verify your identity using a phone number"
    >
      <PhoneInput
        value={values.phone}
        isError={Boolean(errors.phone || networkError)}
        helperText={errors.phone || networkError}
        onChange={handleChange}
      />
    </WidgetWrapper>
  );
}
